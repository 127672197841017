import * as React from "react"
import {buildSeo} from "../utils/seo";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import Awards from "../components/section/awards";
//import {AnimatedShape} from "../components/cases/animated-shape";
import {MarqueeTitle} from "../components/cases/marquee-title";

const HoverElement = ({image , children, callback}) => {
    let el = useRef();

    useEffect(() => {
        let hover_el = () => { callback(image); };
        let out_el = () => { callback("/images/about/empty.png"); };

        let element = el.current;

        element.addEventListener("mouseover", hover_el);
        element.addEventListener("mouseout", out_el);

        return () => {
            if(element !== null) {
                element.removeEventListener("mouseover", hover_el);
                element.removeEventListener("mouseout", out_el);
            }
        }
    }, [el, callback, image]);

    return (<span style={{textDecoration: "underline"}} ref={el}>{children}</span>);
};

// markup
const AboutPage = () => {
    const [overlay, setOverlay] = useState("/images/about/empty.png");

    const overlayRef = useRef();

    useEffect(() => {
        /*if(overlay == "/images/about/empty.png")
            gsap.to(overlayRef.current, {opacity: 0, duration: 0.2});
        else
            gsap.to(overlayRef.current, {opacity: 1, duration: 0.2});*/

    }, [overlay]);

    //               <p>Je suis disponible pour du développement web en freelance, que ce soit du front ou du back !</p>

  return (
      <>
          {buildSeo({
              metaTitle: "Développeur front-end & designer freelance à Annecy",
              title: 'Tout savoir sur Loris',
              description: "Je suis Loris, développeur et designer freelance, spécialisé en e-commerce, vivant à Annecy, et ayant été diplomé des Gobelins. Apprenez-en plus sur moi !"
          })}
          <div className={"page-content about"}>
              <div className={"about-info"}>
                  <div className={"light light-yellow"}></div>
                  <div className={"light light-purple"}></div>

                  <section className={"container"}>
                      <MarqueeTitle text={"À propos. À propos. À propos. À propos. À propos. À propos."}/>
                      <h1 className={"h2"}>Loris Pinna,<br/>votre freelance</h1>
                      <article>
                          <p>Basé sur <HoverElement callback={(data) => {setOverlay(data)}} image={"/images/about/annecy.png"}>Annecy</HoverElement> & Genève, <a href={"/prestations/"}>je suis le designer & le développeur qu'il vous faut</a>. À la fois avide de belles couleurs, d'animations aux petits oignons & de performances, je suis celui qui transformera vos idées, en un site qui émerveillera vos clients.</p>
                          <p>Côté développement, je me suis spécialisé à la fois en <strong>React</strong> et dans des CMS comme <a href={"/prestations/e-commerce/"}>Prestashop</a> (pour vos sites marchands) ou <strong>Wordpress</strong> (pour vos sites vitrines ou boutiques via WooCommerce).</p>
                          <p>Je suis diplômé du Master Design & Management de l'Innovation Interactif de l'école des <HoverElement callback={(data) => {setOverlay(data)}} image={"/images/about/gobelins.png"}>Gobelins</HoverElement>, spécialité développeur. J'ai développé de fortes compétences en e-commerce, en travaillant pour l'agence <HoverElement callback={(data) => {setOverlay(data)}} image={"/images/about/mdn.png"}>Maison du Net</HoverElement>, spécialisée Prestashop et webmarketing.</p>
                      </article>
                  </section>

                  <div className={"about-decoration"}>
                      <div  className={"about-decoration-image"}>
                          <img ref={overlayRef} src={overlay} alt={"Overlay"} className={"overlay"}/>
                          <img src={"/images/profile.png"} alt={"Loris Pinna"}/>
                      </div>
                  </div>
              </div>
              <Awards/>
              <section className={"relative container py-16"}>
                  <h2 className={"md:max-w-50 mb-8"}>Un site merveilleux,<br/>en 3 étapes</h2>
                  <div className={"steps-grid"}>
                      <div className={"service-item"}>
                          <h3>Etape 1 : vous découvrir</h3>
                          <p>Parce que chaque projet est unique, je découvrirais votre projet en détail, en posant des questions pour créer une expérience correspondant à vos besoins. Vous construirons main dans la main ce qu'il vous faut !</p>
                      </div>
                      <div className={"service-item"}>
                          <h3>Etape 2 : construire votre projet</h3>
                          <p>Que ce soit par le design ou par le développement, il faut construire votre projet. Ma rigourosité va vous permettre d'avoir un site à la fois impressionnant, mais aussi performant. Vous serez fier de votre site !</p>
                      </div>
                      <div className={"service-item"}>
                          <h3>Etape 3 : vous former & vous assister</h3>
                          <p>Vous avez votre site, et il est temps de vous apprendre à l'utiliser. Comment modifier les contenus ? Comment renseigner les produits ? Vous ne serez pas perdus et je vous guiderai après la mise en ligne !</p>
                      </div>
                  </div>
              </section>
          </div>
      </>
  )
}

export default AboutPage
