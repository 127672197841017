import * as React from "react"

const Awards = (props) => {
    return (
        <section className={"section blue-section  to-logo__white"}>
            <div className={"container py-16"}>
                <div className={"md:w-75"}>
                    <div className="md:w-75"><h2>Récompenses</h2>
                        <div className="py-8 border-b font-bold text-metropolis md:flex space-between">
                            <div className="text-white text-20">PINNA.DEV - Portfolio</div>
                            <div className="">Honorable Mention @ Awwwards</div>
                        </div>
                        <div className="py-8 border-b font-bold text-metropolis md:flex space-between">
                            <div className="text-white text-20">MDN - maisondunet.com</div>
                            <div className="">Honorable Mention @ Awwwards</div>
                        </div>
                        <div className="py-8 border-b font-bold text-metropolis md:flex space-between">
                            <div className="text-white text-20">MDN - maisondunet.com</div>
                            <div className="">Mobile Excellence @ Awwwards</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Awards;
